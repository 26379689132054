/* ---- SITE.CSS ----
   Contains the majority of the CSS used to format the main structure of the website. Default
   formatting is for mobile browsing. Additional media queries in seperate files have been included.
*/
* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
/* -- CSS RESET -- */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin:0; padding:0; border:0; font-size:100%; vertical-align:baseline; }
body { line-height:1; }
ol, ul { list-style:none; }
strong { font-weight:bold; }
em { font-style:italic; }
blockquote, q { quotes:none; }
blockquote:before, blockquote:after, q:before, q:after { content:''; content:none; }
table { border-collapse:collapse; border-spacing:0; }
:focus { outline:none; }

/* -- HTML5 display-role reset for older browsers -- */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display:block; }

/* -- Generic styles -- */
body {
    font-family: 'Work Sans', sans-serif;
    background-color:#fff;
    font-size: 20px;
    line-height: 30px;
    text-align:left;
    font-weight:300;
}
html, body {
width:100%;
height:100%;
}

html {
  scroll-behavior: smooth;
}

* { 
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

::selection { background:#04a758; color:#fff; }
::-moz-selection { background:#8558a8; color:#fff; }

abbr { border-bottom:1px dotted #545454; }
a abbr { border-bottom:none; }

a {
    color:#ffffff;
    text-decoration:none;
    -moz-transition:all 0.5s;
    -webkit-transition:all 0.5s;
    -o-transition:all 0.5s;
    transition:all 0.5s;
 }
a:hover { text-decoration:underline; }

.hideme
{
    opacity:0;
}
.ninja { display:none !important; }
.clear { clear:both; }
.sink { clear:both !important; display:block !important; height:0 !important; line-height:0 !important; font-size:0 !important; margin:0 !important; padding:0 !important; }
.no-bottom { margin-bottom:0 !important; padding-bottom:0 !important; }
.no-top { margin-top:0 !important; padding-top:0 !important; }
.no-wrap { white-space:nowrap !important; }
.bold,.strong{font-weight:500;}
.pull-margin{ margin-bottom:-6px !important; }
.push-bottom{ padding-bottom:20px !important; }
.push-bottom-30{ padding-bottom:30px !important; }
.push-bottom-40{ padding-bottom:40px !important; }
.push-bottom-50{ padding-bottom:50px !important; }
.push-top{ padding-top:20px }
.push-top-30{ padding-top:30px }
.push-top-40{ padding-top:40px }
.push-top-50{ padding-top:50px }
.push-right{
    @media (min-width: 766px) {
        padding-right:30px;
    }
}
.push-left{
    @media (min-width: 766px) {
        padding-left:30px;
    }
}

.highight{ color: #63ffb3;
    opacity: 0.7;}

.large-text { font-size:14px; line-height:22px; }
.larger-text { font-size:16px; line-height:24px; }
.text-center{ text-align:center !important; }

p.subdued{
    opacity: 0.4;
}

.formatted{
    font-weight:200;
    color:#2D2D2D;
    font-weight: 400;

    a{
        color:#1967DD;
    }

    h1{
        font-size:38px;
        color:#000;
        line-height:1.1;
        margin-bottom:50px;

        &.large{
            margin-bottom:70px;
            font-size:82px;
        }
    }

    h2{
        font-size:28px;
        margin-bottom: 30px;
        font-weight: 600;
        line-height: 1.1;

        &.large{
            font-size:38px;
            color:#000;
            line-height:1.1;
        }

        &.excerpt{
            font-family: TimesNewRomanPSMT;
            font-size: 32px;
            line-height: 1.2;
            font-weight: 100;
        }
    }

    p{
        margin-bottom: 30px;
        font-size:20px;
        line-height:1.4;
    }
    p.subdued{
        opacity: 0.4;
    }
    /* to stop extra padding on p tag, remove if issue */
    p:last-child {
    margin-bottom:10px;
    }

    blockquote{
        width: 870px;
        margin-left: -148px;
        margin-bottom:30px;
        text-align:center;

        p{
            color: #4A4A4A;
            letter-spacing: -1px !important;
            font-size: 29px;
            line-height: 38px;
        }

        del{
            text-decoration: none;
            display: block;
            font-size: 24px;
            margin-top: 15px;
        }
    }

    h3{
        font-size: 14px;
        letter-spacing: 1px;
        margin-bottom: 30px;
        font-weight: 400;
        line-height: 1.1;

        &.large{
           font-size:28px;
           letter-spacing:0;
           font-weight:600;
        }
    }

    img {
        /*margin:30px 0;*/
    }

}

.no-mobile{
    display:none;

    @media (min-width: 480px) {
        display:inline;
    }
}
.no-tablet{
    display:none;

    @media (min-width: 480px) {
        display:none;
    }
    @media (min-width: 960px) {
        display:inline;
    }
}
.hideme{
    opacity:0;
}

.inner {
    margin:0 auto;
    padding:0 5.5%;
    text-align:left;
    width:100%;
    position:relative;

    @media (min-width: 480px) {
        //width:460px;
    }
    @media (min-width: 768px) {
        width:748px;
    }
    @media (min-width: 960px) {
        width:940px;
    }
    @media (min-width: 1200px) {
        width: 100%;
        max-width: 1620px;
    }
}

.padded {
    padding:0 0;

    @media (min-width: 480px) {
        padding:0 50px;
    }
    @media (min-width: 768px) {
        padding:0 60px;
    }
    @media (min-width: 960px) {
        padding:0 70px;
    }
}

.tight{
        
    @media (min-width: 480px) {
        
    }
    @media (min-width: 768px) {
        padding: 0 150px;
    }
    @media (min-width: 960px) {
        padding: 0 220px;
    }
    @media (min-width: 1200px) {
        max-width:1220px !important;
    }
}

.tighter{
        padding: 0 80px;
    @media (min-width: 480px) {
        
    }
    @media (min-width: 768px) {
        padding: 0 240px;
    }
    @media (min-width: 960px) {
        padding: 0 300px;
    }
    @media (min-width: 1200px) {
        padding: 0 360px;
    }
}

.block{
    padding-bottom:50px;
}

.location{
    color:#A7A7A7;

    &::before{
        content:"";
        display:inline-block;
        width:10px;
        height:15px;
        position:relative;
        background:url("../img/location.png") center no-repeat;
        padding-right:20px;
    }

    a{
        color:#a7a7a7;
        text-decoration:none;
    }

}

ul.bullet{
padding-left: 30px;

li{
    padding-bottom:12px;
}
li::before {
  content: "\2022";
  color: black;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}
}