/*page structure*/

#nav{
    width:100%;
    position: fixed;
    z-index: 99;
    padding-top:40px;
    color:#222222;

    @media (min-width: 766px) {
        padding-top:80px;
    }


    .logo{
        position:absolute;

        @media (min-width: 766px) {
            float:left;
            position:static;
        }
        
        a{
            color:#111;
            font-size:16px;
            font-weight: 600;

            &.active{
                border-bottom: 2px solid #424242;
                padding-bottom: 6px;
            }
        }
    }

    ul.topnav {
        display:block;
        list-style-type: none;
        margin: 0;
        padding: 0;
        /*overflow: hidden;*/
        float:right;
        text-align:right;

            li{
                float: left;
                padding: 0 16px;

                a{
                    display: inline-block;
                    color: #000;
                    text-align: center;
                    text-decoration: none;
                    transition: 0.3s;
                    font-size: 16px;
                    font-weight: 400;

                    &.active{
                        border-bottom: 2px solid #424242;
                        padding-bottom: 6px;
                    }

                    &:hover{
                        opacity:0.4;
                    } 
                }

                &.icon{
                    display:none;
                }
            }
        }

    /* When the screen is less than 680 pixels wide, hide all list items. Show the list item that contains the link to open and close the topnav (li.icon) */
    @media screen and (max-width:680px) {
        ul.topnav {
            width:100%;

            li{
                display:none;

                &.icon{
                    float:right;
                    display:inline-block;

                    a{
                        font-size:68px;
                        color:#000;

                        &:hover{
                            border:none;
                        }
                    }
                }
            }

            &.responsive{
                position: relative;
                background-color: #fff;
                padding-top: 36px;

                li{
                    float: none;
                    display: block;
                    padding: 13px 0;
                    border-bottom: 1px solid #000;

                    &:first-child{
                        border-top:1px solid #000;
                    }

                    &.icon{
                        position: absolute;
                        right: 0;
                        top: -13px;
                        padding-right: 16px;
                        border:none;

                        a{
                            font-size:68px;
                        }
                    }

                    a {
                        display: block;
                        text-align: center;

                        &.active{
                            border-bottom: none;
                            padding-bottom: 0;
                        }

                        &:hover{
                            text-decoration: none;
                            border:none;
                            color:#5f5f5f;
                        }
                    }
                }
            }
        }
    }
}

#main-container{
    //border: 20px solid #fff;
    //border-radius: 60px;
    background-color:#edeef1;
    min-height:800px;
    transition: background-color 0.3s ease;

    .main-content{
        padding-top:140px;
        padding-bottom:60px;

        @media (min-width: 766px) {
            padding-top:200px;
        }
    }
}

#subscribe{
    text-align:center;
    color:#CDCDCD;

    p{
        margin-bottom:20px;
    }

    .subscription-box{


/* MailChimp Form Embed Code - Classic - 12/17/2015 v10.7 */
#mc_embed_signup form {display:block; position:relative; text-align:left;}
#mc_embed_signup h2 {font-weight:bold; padding:0; margin:15px 0; font-size:1.4em;}
#mc_embed_signup input {border: 1px solid #ABB0B2; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px;}
#mc_embed_signup input[type=checkbox]{-webkit-appearance:checkbox;}
#mc_embed_signup input[type=radio]{-webkit-appearance:radio;}
#mc_embed_signup input:focus {border-color:#333;}

#mc_embed_signup .button {
        position: absolute;
    /* clear: both; */
    background-color: #000;
    border: 0 none;
    border-radius: 4px;
    transition: all 0.23s ease-in-out 0s;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    height: 32px;
    line-height: 32px;
    margin: 0 5px 10px 0;
    padding: 0 22px;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    white-space: nowrap;
    width: auto;
    right: 0;
    width: 25%;
    z-index: 99;
    height: 55px;
}

#mc_embed_signup .mc-field-group input {
    font-size: 20px;
    display: block;
    width: 75%;
    padding: 15px 0;
    text-indent: 4%;
}


#mc_embed_signup .button:hover {background-color:#777;}
#mc_embed_signup .small-meta {font-size: 11px;}
#mc_embed_signup .nowrap {white-space:nowrap;}

#mc_embed_signup .mc-field-group {clear:left; position:relative; width:100%; padding-bottom:3%; min-height:50px;}
#mc_embed_signup .size1of2 {clear:none; float:left; display:inline-block; width:46%; margin-right:4%;}
* html #mc_embed_signup .size1of2 {margin-right:2%; /* Fix for IE6 double margins. */}
#mc_embed_signup .mc-field-group label {display:block; margin-bottom:3px;}
#mc_embed_signup .mc-field-group select {display:inline-block; width:99%; padding:5px 0; margin-bottom:2px;}

#mc_embed_signup .datefield, #mc_embed_signup .phonefield-us{padding:5px 0;}
#mc_embed_signup .datefield input, #mc_embed_signup .phonefield-us input{display:inline; width:60px; margin:0 2px; letter-spacing:1px; text-align:center; padding:5px 0 2px 0;}
#mc_embed_signup .phonefield-us .phonearea input, #mc_embed_signup .phonefield-us .phonedetail1 input{width:40px;}
#mc_embed_signup .datefield .monthfield input, #mc_embed_signup .datefield .dayfield input{width:30px;}
#mc_embed_signup .datefield label, #mc_embed_signup .phonefield-us label{display:none;}

#mc_embed_signup .indicates-required {text-align:right; font-size:11px; margin-right:4%;}
#mc_embed_signup .asterisk {color:#e85c41; font-size:150%; font-weight:normal; position:relative; top:5px;}     
#mc_embed_signup .clear {clear:both;}

#mc_embed_signup .mc-field-group.input-group ul {margin:0; padding:5px 0; list-style:none;}
#mc_embed_signup .mc-field-group.input-group ul li {display:block; padding:3px 0; margin:0;}
#mc_embed_signup .mc-field-group.input-group label {display:inline;}
#mc_embed_signup .mc-field-group.input-group input {display:inline; width:auto; border:none;}

#mc_embed_signup div#mce-responses {float:left; top:-1.4em; padding:0em .5em 0em .5em; overflow:hidden; width:90%; margin: 0 5%; clear: both;}
#mc_embed_signup div.response {margin:1em 0; float:left; top:-1.5em; z-index:1; margin-bottom:20px;}
#mc_embed_signup #mce-error-response {display:none;}
#mc_embed_signup #mce-success-response {color: #002bc6; font-weight: 500; display:none;}
#mc_embed_signup label.error {display:block; float:none; width:auto; margin-left:1.05em; text-align:left; padding:.5em 0;}

#mc-embedded-subscribe {clear:both; width:auto; display:block; margin:1em 0 1em 5%;}
#mc_embed_signup #num-subscribers {font-size:1.1em;}
#mc_embed_signup #num-subscribers span {padding:.5em; border:1px solid #ccc; margin-right:.5em; font-weight:bold;}

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {display:inline-block; margin:2px 0 1em 0; padding:5px 10px; background-color:rgba(255,255,255,0.85); -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; font-size:14px; font-weight:normal; z-index:1; color:#e85c41;}
#mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {border:2px solid #e85c41;}


    }

    p.numbers{
        font-size:14px;
    }
}

#portfolio-items{
        .project-large{
            width:100%;
            height:210px;
            background:#ccc;
            margin-bottom:40px;
            display:block;
            overflow:hidden;

            @media (min-width: 480px) {
                
            }
            @media (min-width: 768px) {
                height:310px;
            }
            @media (min-width: 960px) {
                height:410px;
            }

            &.studentbeans{
                background:url('../img/sb-tile.png');
                background-size: cover;
                background-position: center;
                background-color:#fff;

                @media (min-width: 480px) {
                    background-image:url('../img/studentbeans-banner.jpg');
                }

            }
        }

        .project-small{
            width:100%;
            height:498px;
            background:#ccc;
            display:block;
            overflow:hidden;
            margin-bottom:40px;

            @media (min-width: 480px) {
                height:790px;
            }
            @media (min-width: 768px) {
                height: 620px;
                float:left;
                display:inline;
                width:48%;
                margin-bottom:0;
            }
            @media (min-width: 960px) {
                height:785px;
            }
            @media (min-width: 1200px) {
                height:865px;
            }           

            &.left{
                margin-right:4%;
                margin-bottom:40px;
            }

            &.sb-1{
                background:url('../img/sb-apps-4.png');
                background-size: cover;
                background-position: center;
                margin-bottom:100px;
            }
            &.sb-cms{
                background:url('../img/sb-cms-2.png');
                background-size: cover;
                background-position: center;
            }

            &.pfd{
                background:url('../img/pfd.png');
                background-size: cover;
                background-position: center;
            }

            &.sb-2{
                background:url('../img/sb-verification.png');
                background-size: cover;
                background-position: center;
            }

            &.sb-explore{
                background:url('../img/sb-explore.png');
                background-size: cover;
                background-position: center;
            }

            &.sb-colab{
                background:url('../img/gateway-tile.png');
                background-size: cover;
                background-position: center;
            }

            &.blynker{
                background:url('../img/blynker-3.png');
                background-size: cover;
                background-position: center;
            }

            &.snapita{
                background:url('../img/snapita-2.png');
                background-size: cover;
                background-position: center;
            }
            &.sb-app-reviews{
                background:url('../img/sb-app-reviews.png');
                background-size: cover;
                background-position: center;
            }
            &.wanda{
                background:url('../img/wanda-2.png');
                background-size: cover;
                background-position: center;
            }
            &.sb-map{
                background:url('../img/sb-map.png');
                background-size: cover;
                background-position: center;
            }
            &.trustatrader{
                background:url('../img/trustatrader.png');
                background-size: cover;
                background-position: center;
            }
        }

        a{

            .project-info-wrap{
                height:100%;
                background-color:rgba(0, 0, 0, 0);
                position:relative;
                cursor: pointer;

                transition: background-color 0.2s ease;
                -moz-transition: background-color 0.2s ease;
                -ms-transition: background-color 0.2s ease;
                -o-transition: background-color 0.2s ease;
                -webkit-transition: background-color 0.2s ease;


                .project-info{
                    color:#fff;
                    font-size:18px;
                    max-width:350px;
                    padding:25px;
                    opacity:0;
                    position:absolute;
                    bottom:-200px;

                  -webkit-transition: all 0.5s ease;
                  -moz-transition: all 0.5s ease;
                  -o-transition: all 0.5s ease;
                  -ms-transition: all 0.5s ease;
                  transition: all 0.5s ease;

                    h3{
                        font-weight:400;
                        padding-bottom:10px;
                        font-size:16px;
                        letter-spacing: 1px;
                    }

                    p{
                        span{
                            font-weight: 500;
                            text-transform: uppercase;
                            font-size:15px;
                        }
                    }
                }
            }
            &:hover{
                text-decoration: none;

                .project-info-wrap{
                    background-color:rgba(3, 19, 39, 0.74);

                    .project-info{
                        display:block;
                        opacity:1;
                        bottom:0px;
                    }
                }
            }
        }
    
}

#more-photo-sets{

    padding-top:50px;

    ul.photo-posts{

        a{
            width:100%;
            position: relative;
            text-decoration: none;
            display: inline-block;
            margin-bottom:30px;

            @media (min-width: 768px) {
                width:calc(50% - 14px);
                vertical-align: top;    
            }

            &:first-child{
                margin-right:20px;
            }

            li{

                .image-container{
                    width:100%;

                    .image{
                        background-color:#222;
                        position:relative;

                        padding-top: 56.25%;  /* 450px/800px = 0.5625 */
                        background-size: cover;
                        -moz-background-size: cover;  /* Firefox 3.6 */
                        background-position: center;  /* Internet Explorer 7/8 */
                    }
                }

                span.info{
                    display:block;
                    color:#2d2d2d;
                    margin-top:5px;

                        @media (min-width: 768px) { 
                            margin-top:20px;
                        }

                    h3.title{

                    }

                    p.excerpt{
                        font-size:16px;
                    }
                }
            }
        }
    }
}

#footer{
    min-height: 30px;
    font-weight: 400;
    background-color: #2a6244;
    color:#fff;
    font-size: 16px;

    .footer-wrap{
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        padding: 32px 5%;

        ul{
            display:flex;

            li{
                margin-left:10px;
            }
        }
    }
}