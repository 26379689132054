/* -- Home page -- */

#home{
    #main-container{
        background-color:#faf5ed;
    }

    #nav{
        color:#111;

        .logo a {
            color:#111;
        }
        .icon a {
            color: #111 !important;
        }
        li a{
            @media (min-width: 768px) {
                color:#111;
            }
            
        }
    }

    span.external{
        font-size: 14px;
        vertical-align: middle;
        margin-left: 5px;
        opacity: 0.5;
    }

    .divider{
        width: 110px;
        margin: 82px auto;
    }

}

#header{
    margin:0 auto;

    .introduction{
        padding-top: 200px;

        #intro-container{
            position:relative;
            z-index: 1;
                
            #intro{
                color:#111;
                width:100%;
                @media (min-width: 768px) {
                    max-width:50%;
                }

                br{
                    @media (max-width: 480px) {
                        display:none;
                    }       
                }

                h1{
                    font-weight:600;
                    font-size:24px;
                    line-height: 1.3;
                    padding-bottom: 10px;
                    // text-shadow: 2px 2px 1px #171717;

                    @media (min-width: 768px) {
                        font-size:34px;
                    }
                    @media (min-width: 960px) {
                        font-size:36px;
                    }

                    span{
                        position: relative;
                        z-index: 2;
                    }

                    span::after{
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 5px;
                        left: 0;
                        height: 7px;
                        width: 100%;
                        background: #04a758;
                        opacity: 0.4;
                        z-index: 1;

                    }
                }

            }
            #image-stack {
                display:block;
                position: relative;
                width: 191px;
                height: 223px;
                top: -47px;


                @media (min-width: 768px) {
                    display:block;
                    position: absolute;
                    width: 231px;
                    height: 273px;
                    right: 42px;
                    top: 0;
                }
                @media (min-width: 960px){
                    width: 316px;
                    height: 393px;
                    top: 20px;
                }
                @media (min-width: 1200px){
                    width: 380px;
                    height: 453px;
                    top: 20px;
                    right:12%;
                }
              
                .image-container {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  transition: transform 0.3s ease, z-index 0.3s;
                  background-size: cover;
                  cursor: pointer;
                  box-shadow: 0 0 0 8px #faf5ee, 0 4px 8px #0000002e;
                  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
                  border-radius: 20px;
              
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
                  }
              
                  &.dragging {
                    cursor: grabbing;
                    z-index: 5;
                    transition: none;
                  }
              
                  &[data-index="1"] {
                    z-index: 4;
                  }
              
                  &[data-index="2"] {
                    z-index: 3;
                    transform: translateY(15px);
                  }
              
                  &[data-index="3"] {
                    z-index: 2;
                    transform: translateY(30px);
                  }
              
                  &[data-index="4"] {
                    z-index: 1;
                    transform: translateY(45px);
                  }
                }
              }


            &::after{

                @media (min-width: 768px) {
                    
                    display: block;
                    position: absolute;
                    top: 0px;
                    right: 50px;
                    height: 259px;
                    width: 240px;
                    z-index: -99;
                    border-radius: 27px;
                    opacity: 1;
                    background-image:url('../img/chris-weston.png');
                    background-size:cover;
                    background-position:center;
                    rotate: 5deg;
                    filter: drop-shadow(0px 4px 29px rgba(0, 0, 0, 0.35));
                }

                @media (min-width: 960px){
                    top: 68px;
                    right: 50px;
                    height: 452px;
                    width: 390px;
                }

            }
        }
    }
}

#about{
    color:#111;
    font-weight: 400;
    font-size:18px;
    line-height:1.5;
    padding: 32px 0 100px 0;

    .about-me{

        h3{
            @media (min-width: 960px) {
                           
            }
        }

        .about-me-content{
            a{
                color: #111;
                text-decoration: underline;
                -webkit-text-decoration-color: #11111138;
                text-decoration-color: #11111138;
                text-underline-offset: 5px;
            }
        }

    }

    .about-column-1{
        font-size:16px;
        @media (min-width: 768px) {
            font-size:18px;
        }
        width:100%;
        float:left;

        @media (min-width: 480px) {
            
        }
        @media (min-width: 768px) {
            margin-bottom:30px;
        }
        @media (min-width: 960px) {
            width:50%;
            padding-right:2%;
        }
        @media (min-width: 1200px) {

        }

        p{
            margin-bottom:20px;
        }
    }

    ul#links{
        display:flex;

        li{
            margin-right:20px;
        }
    }


    .bold{
        line-height: 1;
    }
}


#experience{
    color:#fff;
    font-weight: 400;
    padding-bottom:150px;

    .experience-wrapper{
        max-width:690px;
        margin:0 auto;
    }

    .section-title{
        padding-right:2%;
        margin-bottom:25px;


        @media (min-width: 480px) {
            
        }
        @media (min-width: 768px) {

        }
        @media (min-width: 960px) {
        }
        @media (min-width: 1200px) {

        }

        a{
            color:#fff;
            font-size:18px;
            font-style: normal;
            text-decoration:none;
        }

        h3{
            @media (min-width: 768px) {
                font-size:28px;
            }

            color: #fff;
            font-size: 20px;
            letter-spacing: 1px;
            padding-bottom: 50px;
            font-weight: 600;
    
            &::after{
    
                @media (min-width: 768px) {
                    content: '';
                    display: block;
                    position: relative;
                    top: 10px;
                    right: -157px;
                    height: 35px;
                    width: 42px;
                    z-index: 99;
                    opacity: 1;
                    background-image:url('../img/arrow.png');
                    background-size:cover;
                    background-position:center;
                }
    
            }
        }
    }

    .exp{
        //border: 1px solid #ffffff29;
        padding: 20px;
        border-radius: 20px;
        background: #ffffff03;

        @media (min-width: 768px) {
            padding: 50px;
        }

        #hidden-experience {
            height: 0;
            overflow: hidden;
            opacity: 0;
            transition: height 0.3s ease, opacity 0.3s ease;
          }
        
          #hidden-experience.visible {
            height: auto;
            opacity: 1;
          }
        
          #toggle-button {
            margin-top: 10px;
            padding: 10px 20px;
            cursor: pointer;
            background-color: #fff;
            color: #111;
            border: none;
            border-radius: 99px;

            .down-chevron{
                transform: rotate(-90deg);
                display: inline-block;
                margin-right: 10px;
            }
          }
        
          #toggle-button:hover {
            background-color: #4dba86;
          }

          .job-role {
            margin-bottom: 30px;
            border-bottom: 1px solid #ffffff14;
                
              
                .image-row {
                  display: flex;
                  gap: 3%;
                  margin-top: 10px;
                  margin-bottom: 2rem;
                  overflow: hidden;
              
                  .thumbnail {
                    width: 105px;
                    height: 63px;
                    object-fit: cover;
                    cursor: pointer;
                    border-radius: 5px;
                    transition: transform 0.2s ease;
              
                    &:hover {
                      transform: scale(1.1);
                    }
                  }
                }
              
                .modal {
                    display: none;
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.8);
                    z-index: 99;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column; // Ensure content stacks vertically
                  
                    .close {
                      position: absolute;
                      top: 10px;
                      right: 15px;
                      color: white;
                      font-size: 30px;
                      font-weight: bold;
                      cursor: pointer;
                  
                      &:hover {
                        color: #ccc;
                      }
                    }
                  
                    .modal-content {
                      max-width: 50%;
                      max-height: 50%;
                      margin-bottom: 15px; // Space between the image and description
                      border-radius: 8px;
                      object-fit: contain;
                    }
                  
                    .description {
                      color: white;
                      font-size: 16px;
                      text-align: center;
                      max-width: 80%;
                      margin: 0 auto;
                      line-height: 1.5;
                    }
                  }
                  
              

        }
    
        .company-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    
        .company{
            display: flex;
            align-items: center;
            margin-bottom:8px;
        }
        .company::before{
            content: '';
            margin-right:10px;
            display: inline-block;
            height:40px;
            width:40px;
            background-image: url(../img/sb-logo.png);
            background-size: cover;
            background-position: center;
        }
    
        .getme::before{
            background-image: url(../img/getme-logo.png);
        }
        .worcester::before{
            background-image: url(../img/worc-logo.png);
        }
        .ixdf::before{
            background-image: url(../img/ixdf-logo.png);
        }
        .snapita::before{
            background-image: url(../img/snapita-logo.png);
        }
        .dates{
            font-size: 16px;
        }
        .role{
            font-size: 16px;
            margin-bottom:16px;
        }
    
        .experience-summary{
            font-size:14px;
            line-height: 1.5;
            margin-bottom: 32px;
            opacity: 0.7;
            @media (min-width: 960px) {
                font-size:16px;
            }
            p{
                margin-bottom:18px;
            }
        }
    }


}

#portfolio{
    scroll-behavior: smooth;
    color:#111;
    font-weight: 400;


    .section-title{
        padding-right:2%;
        margin-bottom:25px;


        @media (min-width: 480px) {
            
        }
        @media (min-width: 768px) {

        }
        @media (min-width: 960px) {

        }
        @media (min-width: 1200px) {

        }

        a{
            color:#fff;
            font-size:18px;
            font-style: normal;
            text-decoration:none;
        }

        h3{
            @media (min-width: 768px) {
                font-size:28px;
            }

            color: #111;
            font-size: 20px;
            letter-spacing: 1px;
            padding-bottom: 50px;
            font-weight: 600;
    
            &::after{
    
                @media (min-width: 768px) {
                    content: '';
                    display: block;
                    position: relative;
                    top: 18px;
                    right: -276px;
                    height: 35px;
                    width: 42px;
                    z-index: 99;
                    opacity: 1;
                    background-image:url('../img/arrow-dark.png');
                    background-size:cover;
                    background-position:center;
                }
    
            }
        }
    }

    .portfolio-wrapper{
        padding: 0 3.5%; /* This creates the bumper */
    }

    .project{
        margin: 0px auto 64px auto;
        max-width:1666px;

        border: 1px solid #11111117;
        padding: 20px;
        border-radius: 30px;
        box-shadow: 0 4px 10px -7px #1111112b;

        a{
            text-decoration: underline;
        }

        .project-about{
            max-width: 1020px;
            margin: 0 auto;
            margin-bottom: 62px;
        }

        .project-title{
            margin-bottom: 18px;
            h2{
                font-size:23px;
                margin-bottom: 16px;
                font-weight: 600;
            }
            p{
                font-size: 16px;
                @media (min-width: 768px) {
                    font-size:18px;
                }
            }
        }

        .read-more {
            cursor: pointer;
            color: #04a758;
            font-size: 16px;
            display: inline-flex;
            align-items: center;
            gap: 5px;
        }
        .read-more::after {
            content: "›";
            rotate: 90deg;
            font-size: 20px;
            margin-left: 6px;
            transition: transform 0.3s ease;
        }
        .read-more.expanded::after {
            transform: rotate(180deg);
        }
        .project-details {
            display: none;
            grid-template-rows: 0fr;
            overflow: hidden;
            transition: grid-template-rows 0.3s ease-out;
        }
        .project-details.open {
            display:grid;
            grid-template-rows: 1fr;
        }
        
        ul.badges{
            margin-bottom:16px;
        }

        li.badge{
            background-color: rgb(0 0 0 / 5%);
            margin-right: 4px;
            display: inline-block;
            padding: 2px 14px;
            font-size: 12px;
            color: rgb(0 0 0 / 53%);
            border-radius: 9px;
            margin-bottom: 9px;
        }

        .project-intro{
            font-size:16px;
            line-height: 1.5;
            opacity: 1;
            @media (min-width: 768px) {
                font-size:18px;
            }
            p{
                margin-bottom:18px;
            }
        }

        .project-images{
            margin-bottom: 32px;
                img{
                    border-radius: 24px;
                }
        }

        .project-carousel {
            margin: 2rem 0;
          }
          
          .carousel {
            position: relative;
            overflow: hidden;
            width: 100%;
            border-radius: 20px;
            margin: auto;
            margin-bottom: 32px;
          }
          
          .carousel-inner {
            display: flex;
            transition: transform 0.5s ease-in-out;
          }
          
          .carousel-item {
            min-width: 100%;
            box-sizing: border-box;
          }
          
          .carousel-item img {
            width: 100%;
            display: block;
          }
          
          .carousel-prev,
          .carousel-next {
            position: absolute;
            top: 50%;
            font-size: 12px;
            border-radius: 99px;
            width:32px;
            height:32px;
            transform: translateY(-50%);
            background-color: rgba(255, 255, 255, 0.5);
            color: #000;
            border: none;
            cursor: pointer;
            z-index: 1000;
          }
          
          .carousel-prev {
            left: 8px;
          }
          
          .carousel-next {
            right: 8px;
          }
          
          .carousel-count{
            position: relative;
            top: -24px;
            font-size: 14px;
            margin-right: 19px;
            text-align: right;
            opacity: 0.7;
          }

        .project-impact{
            font-size:16px;
            

            @media (min-width: 768px) {
                display: flex;
                flex-wrap: nowrap;
                gap:72px;
            }

            .contribution{
                margin-bottom:32px;
                margin-left: 20px;
                flex:55%;
            }

            .impact{
                flex:45%;
                margin-left:28px;
            }

            h5{
                font-weight: 500;
                font-size: 16px;
                margin-bottom: 8px;
                position: relative;
                left: -20px;
            }

            ul{
                opacity: 0.7;
                list-style-type:circle;
                line-height: 1.5;
            }
        }
    }

 

}


#life{
    background: #397253;
    padding-top: 82px;
    padding-bottom:62px;
    color:#fff;

    .life-wrapper{


        h3{
            font-size:20px;
            letter-spacing: 1px;
            padding-bottom: 50px;
            font-weight: 600;

            @media (min-width: 768px) {
                font-size: 28px;   
            }

            .subdued{
                opacity:0.5;
            }
        }

        .life-container{

            @media (min-width: 768px) {
                max-width: 50%;
                font-size:18px;
            }
            
            font-size:16px;
            line-height: 150%;
            font-weight: 400;

            h4{
                font-weight: 500;
            }

            p{
                padding-bottom: 16px;
                opacity: 0.7;

                a{
                    text-decoration: underline;
                }
            }
        }

        #life-image-stack{
            display:none;
            height:2px;

            @media (min-width: 768px) {
                display:block;
                position:absolute;
                right:0;
                top:140px;
            }

            @media (min-width: 960px) {
                right:10px;
            }

            @media (min-width: 1200px) {
                right:12%;
                top:-10px;
            }

            .image-container{
                width: 270px;
                height: 280px;
                position: relative;
                background-size: cover;
                border-radius: 10px;
                box-shadow: 0 0 0 4px #70ba92, 0 4px 8px #0000002e;
                filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
                transition: transform 0.2s ease, box-shadow 0.2s ease;
                cursor: pointer;

                &:hover {
                    transform: scale(1.02) rotate(3deg); // Enlarge and rotate slightly
                filter: drop-shadow(0px 14px 16px rgba(0, 0, 0, 0.1));
                  }
            }
        }
    }
}

#dribbble{
    background-color: #fff;
    padding-bottom: 150px;

    h3{
        color: #000;
        font-size: 14px;
        letter-spacing: 1px;
        padding-bottom: 50px;
        font-weight: 400;
    }
}

#shots {
  display: flex;
  flex-wrap: wrap;
  max-width: 1080px;
  margin: auto;
  
  .shot {
    position: relative;
    display: block;
    width: 25%;
    background: pink;
    color: white;
    text-decoration: none;
    
    &:hover {
      img {
        opacity: .1;
      }
      .title {
        opacity: 1;
      }
    }
    
    .title {
      position: absolute;
      width: calc(100% - 2rem);
      padding: 1rem;
      opacity: 0;
      transition: opacity .2s ease-in-out;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 1px;
    }

    img {
      display: block;
      max-width: 100%;
      transition: opacity .2s ease-in-out;
    }
  }
}

.button{
    grid-column-gap: 7px;
    grid-row-gap: 7px;
    color: #000;
    border: 1px solid #ddced3;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    transition: all .2s;
    display: inline-flex;
    
    &--primary {
        color: #fff;
        background-image: linear-gradient(#444, #000);
        border: 1px solid #000;
        box-shadow: 0 1px 2px #0000004d, 0 0 #000, inset 1px 1px .25px #ffffff4d, inset 0 2px 1px #ffffff80;
      
      &:hover {
        text-decoration: none;
        box-shadow: 0 9px 8px #0003, 0 1px 2px #0000004d, 0 0 #000, inset 1px 1px .25px #ffffff4d, inset 0 2px 1px #ffffff80;
      }
    }
    
    &--secondary {
      color: #000;
      
      &:hover {
        text-decoration: none;
        background-color: #fff;
    border-color: #fff;
      }
    }
}