/* -- General Case Study Tempalate -- */

#casestudy{

	#main-container{
		background-color: #fff;
	}

	.main-content{
		padding-top:0;
	}

	#header{
		min-height: 0px !important;

		.hero{
			padding-top: 88px;
			padding-bottom: 200px;

			#intro{
				padding-top:83px;
				color:#fff;

				.title{
					@media (min-width: 480px) {
			            
			        }
			        @media (min-width: 768px) {
			            width:700px;
			            font-size: 30px;
			        }
			        @media (min-width: 960px) {

			        }
			        @media (min-width: 1200px) {

			        }
				}

				h1{
					font-size:60px;
					line-height:1.1;
					padding-bottom:10px;
				}

				h2{
					font-size:30px;
					font-weight:200;
					padding-bottom:35px;
				}
				.description{
					font-weight:200;
					opacity: 0.8;
					font-size: 30px;
					line-height: 1.4;
					display:inline-block;


			        @media (min-width: 480px) {
			            
			        }
			        @media (min-width: 768px) {
			            width:500px;
			            font-size: 30px;
			        }
			        @media (min-width: 960px) {

			        }
			        @media (min-width: 1200px) {

			        }
				}
				.roles{
					font-size:14px;
					line-height:1.6;
					padding-top:30px;
					font-weight:200;
					vertical-align: bottom;

				    @media (min-width: 480px) {
				       
				    }
				    @media (min-width: 768px) {
				        text-align:right;
				         padding-top:0px;
				        float:right;
				    }
				    @media (min-width: 960px) {

				    }
				    @media (min-width: 1200px) {
				        
				    }

					.lead{
						font-weight:500;
					}
				}
			}
		}
	}

	#casestudy-content{
		background-color:#fff;
		min-height:600px;
		position:relative;

		&:nth-child(2){
			   padding-top: 177px;

		    @media (min-width: 480px) {
		        
		    }
		    @media (min-width: 768px) {
		        padding-top: 327px;
		    }
		    @media (min-width: 960px) {
		        
		    }
		    @media (min-width: 1200px) {
		        
		    }
		}

		.casestudy-hero{
			position:absolute;
			width:300px;
			top:-140px;
			background-color:#ccc;
		    left: 0;
		    right: 0;
		    margin-left: auto;
		    margin-right: auto;

		    @media (min-width: 480px) {
		        width:90%;
		        height:275px;
		    }
		    @media (min-width: 768px) {
		        width:748px;
		        height:415px;
		    }
		    @media (min-width: 960px) {
		        width:940px;
		    }
		    @media (min-width: 1200px) {
		        width:1040px;
		    }
		}

		.full-width{
			width:100%;
			height:600px;

		    @media (min-width: 480px) {
		        width:100%;
		    }
		    @media (min-width: 768px) {
		        width:100%;
		    }
		    @media (min-width: 960px) {
		        width:100%;
		    }
		    @media (min-width: 1200px) {
		        width:100%;
		    }
		}

		.block{
			padding-bottom:50px;

		}
		.quote{
			font-size: 25px;
			font-style: italic;
			font-weight:100;
			line-height: 1.4;
			color:#4A4A4A;
			padding: 0 80px 50px 80px;
			text-align:center;
			margin-top: -30px;
		}

		p.caption {
		    font-size: 13px;
		    position: relative;
		    text-align: center;
		    top: -34px;
		}

		h2{
			font-size:36px;
		}

		h3{
			font-size:28px;
			letter-spacing: 0;
    		font-weight: 600;
		}

		.split{
			
			.left{
				display:block;
			}

	        @media (min-width: 480px) {
	            
	        }
	        @media (min-width: 768px)  and (max-width: 959px) {
	        	padding: 0 150px;
	        }
	        @media (min-width: 960px) {

				.left{
					float:left;
					width:50%;
					padding-right:80px;

				}

				.right{
					float:left;
					width:50%;

				}
	        }
	        @media (min-width: 1200px) {

	        }

		}

		.faux-full{
			width:100%;
			max-width:2000px;
			margin:0 auto;
			padding-bottom:50px;
		}

		.carousel{
			margin-bottom:50px;
		}

		.background-black{
			background:#282929;
			padding-top:100px;
			color:#fff !important;
			padding-bottom:50px;
		}

		.breaker{
			width:80%;
			max-width:1000px;
			padding-bottom:50px;
			margin:0 auto;
		}

		.background-white{
			background:#fff;
			padding-top:100px;
			min-height:500px;
			padding-bottom:50px;
		}

	}

}

/* -- Specific to 'Designing The Student Beans App' -- */


	.content-management-system{

		.casestudy-hero{
			    
			    top: -154px !important;
			    
			    @media (min-width: 480px) {
			       
			        height:275px;
			    }
			    @media (min-width: 768px) {
			        
			        height:440px !important;
			    }
			    @media (min-width: 960px) {
			        
			    }
			    @media (min-width: 1200px) {
			       
			    }
		}
	}

