/* -- main theme for Play section -- */

#play{

	#main-container{
		background-color:#fff;
	}
	
	.intro{
		text-align:center;

		h2{
			
		}

		p{
			font-weight: 200;
		}
		
	}

	ul.photo-posts{

		a{
			width:100%;
			position: relative;
			text-decoration: none;
			display: inline-block;
			margin-bottom:30px;

		    @media (min-width: 768px) {
				width:calc(50% - 14px);
				vertical-align: top;    
		    }

			&:nth-child(2n){
			margin-right:20px;
			}

		    @media (min-width: 768px) {   

				&:first-child{
					width:100%;
					margin-right:0;
					display:block;
					margin-bottom:60px;

					.image-container{
						width:100%;

						.image{
							background-color:#333;

							padding-top: 56.25%;  /* 450px/800px = 0.5625 */
							background-size: cover;
							-moz-background-size: cover;  /* Firefox 3.6 */
							background-position: center;  /* Internet Explorer 7/8 */

							&::after{
								content: "";
								position:absolute;
								width:100%;
								bottom:0;
								height:200px;
								background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
								background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
								background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
								filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */

							}
						}
					}

				    span.info{
				    	color:#fff;
				    	position:absolute;
				    	bottom:20px;
				    	left:20px;

						h3.title{

						}

						p.excerpt{

						}
					}
				}
			}

			li{
				.image-container{
					width:100%;

					.image{
						background-color:#222;
						position:relative;

						padding-top: 56.25%;  /* 450px/800px = 0.5625 */
						background-size: cover;
						-moz-background-size: cover;  /* Firefox 3.6 */
						background-position: center;  /* Internet Explorer 7/8 */
					}
				}

			    span.info{
			    	display:block;
			    	color:#2d2d2d;
			    	margin-top:5px;
			    	
			    		@media (min-width: 768px) { 
			    			margin-top:20px;
			    		}

					h3.title{

					}

					p.excerpt{
						font-size:16px;
					}
				}
			}
		}
	}

	.instagram{
		padding-top:60px;

		a{
			color:#000;
			display:block;
			text-align:center;
			width:100%;
			text-decoration: underline;
		}

		#instagram-feed{
			padding-top: 50px;

			#instafeed{
				box-sizing:border-box;
    			-moz-box-sizing:border-box; /* Firefox */

				a{
					float: left;
    				width: calc(33.3333333% - 20px);
    				margin-right: 20px;
    				margin-top:20px;

					img{
						width: 100%;

					}
				}
			}
		}
	}
}
