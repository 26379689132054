.grid{
    box-sizing:border-box;
    -moz-box-sizing:border-box; /* Firefox */
    width:100%;
    /*margin:20px 0;*/
    text-align:left;
    display:table;

    > *, *:after, *:before {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    &:after{
        content: "";
        display: table;
        clear: both;
    }

    &.extra-padding-tb{
        padding-top: 70px !important;
        padding-bottom: 70px !important;
    }
    &.extra-padding-t{
        padding-top: 70px !important;
    }
    &.extra-padding-b{
        padding-bottom: 70px !important;
    }

    &.faux-full{
        max-width: 1170px;
        margin:0 auto;
    }

    &.text-width{
        max-width: 780px;
        margin:0 auto;
    }

    &.grid-pad{

        [class*='col-']{
            padding-right:20px;
            padding-left:20px;

            &:last-of-type {
                padding-right:0;
            }
            &:first-of-type {
                padding-left:0;
            }
        }
    }

    &.collapse{

        @media (max-width: 766px) {
            [class*='col-'] {
                display:block;
                width:100%;
                padding-right:0;
                padding-bottom:25px;

                &.spacer{
                     display:none;
                }
            }
        }
    }
    [class*='col-'] {
        /*float: left;*/
        display: table-cell;
        vertical-align: bottom;

        &.spacer{
             height:0px;
             margin-top:64px;
             margin-bottom:64px;
        }
    }
    .col-1{
        width:8.3333333%;
    }
    .col-2{
        width:16.6666667%
    }
    .col-3{
        width:25%;
    }
    .col-4{
        width:33.3333333%;
    }
    .col-5{
        width:41.6666667%;
    }
    .col-6{
        width:50%;
    }
    .col-7{
        width:58.3333333%;
    }
    .col-8{
        width:66.6666667%;
    }
    .col-9{
        width:75%;
    }
    .col-10{
        width:83.3333333%;
    }
    .col-11{
        width:91.6666667%;
    }
    .col-12{
        width:100%;
    }
}