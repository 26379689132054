#work{

	#main-container{
		background-color:#fff;
	}

	.intro{
		text-align:center;

		h2{
			
		}

		p{
			font-weight: 200;
		}
		
	}
}