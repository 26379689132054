/* -- anything specific to general posts -- */

.sharer{

	margin: 0 auto;
	width:80px;

	a{
		display:inline-block;
		width:30px;
		height:30px;

		&.fb{
			background:url('../img/fb.png') center no-repeat;
			margin-right:10px;
		}

		&.twitter{
			background:url('../img/tw.png')  center no-repeat;
		}
	}

}